
.page-style-kookaku {

/* Layout Components */
.section__primary {
  @apply py-4 md:py-8;
}

.inner, .inner2, .inner3 {
  @apply container mx-auto px-2 md:px-4;
}

/* Title Styles */
.ttl__wrap {
  @apply text-center mb-8;
}

.ttl1 {
  @apply inline-block text-xl md:text-2xl font-bold relative pb-4 after:content-[''] after:absolute after:left-1/2 after:bottom-0 after:w-1/2 after:-translate-x-1/2 after:h-[2px] after:bg-ctaPink;
}

.title__belt {
  @apply py-6 text-center mb-12;
}

.title__belt h2 {
  @apply text-2xl md:text-3xl font-bold mx-auto max-w-4xl leading-loose md:leading-loose;
}

/* Color Classes */
.kaotype__color {
  @apply text-[#f2795b];
}

.frame_type__color {
  @apply text-[#4d82e6];
}

.parsonal_color__color {
  @apply text-[#f46aae];
}

.course1 {
  @apply bg-[#4d82e6] text-white text-center py-2 mb-2 md:mb-4;
}

.course2 {
  @apply bg-[#f2795b] text-white text-center py-2 mb-2 md:mb-4;
}

.course3 {
  @apply bg-[#f46aae] text-white text-center py-2 mb-2 md:mb-4;
}

.course1__color {
  @apply text-[#1A3A84] font-bold;
}

.course2__color {
  @apply text-[#f2795b] font-bold;
}

.course3__color {
  @apply text-[#f46aae] font-bold;
}

.course1__light__color {
  @apply bg-[#E8F2FF];
}

.course2__light__color {
  @apply bg-[#FFF0F5];
}

.course3__light__color {
  @apply bg-[#F3EDFF];
}

/* List Styles */
.list_check {
  @apply mb-3 md:mb-6;
}

.list_check li {
    @apply
    flex
    items-start 
    gap-2
    font-medium
    text-lg
    lg:text-xl
    mb-2
  }

.list_check.s li {
    @apply
    text-sm
    md:text-base
    font-normal
    mb-1
}

.list_check .icon {
  @apply w-[14px] h-[14px] shrink-0 text-white bg-ctaPink p-px rounded-full mt-1 
}

.list_check.c1 svg {
  @apply bg-[#4d82e6];   
}
  
.list_check.c2 svg {
  @apply bg-[#f2795b];   
}
  
.list_check.c3 svg {
  @apply bg-[#f46aae];   
}

/* Item Layouts */
.item2 {
  @apply flex flex-col md:flex-row gap-8 items-center mb-12;
}

.item2 .large__item {
  @apply md:w-2/3;
}

.item2 .small__item {
  @apply md:w-1/3;
}

.three__shindan {
}

/* Image Containers */
.frame_type__image, .twenty_four_type__image {
  @apply mx-auto mt-8;
}

.frame_type__image img, .twenty_four_type__image img {
  @apply w-full h-auto;
}

/* Special Text Styling */
.fz20 {
  @apply text-lg md:text-xl;
}

.fz14 {
  @apply text-sm;
}

.fz12 {
  @apply text-xs;
}

.pc_tac {
  @apply text-center mb-8;
}

.pc__br {
  @apply hidden md:block;
}

.sp__br {
  @apply block md:hidden;
}

.marker {
  @apply bg-[#FFF8D0] px-1 py-0.5 inline-block;
}

/* Course Features Section */
.bg_01 {
  @apply bg-[#F8F8F8];
}

.course__feature {
  @apply relative;
}

.course__table__wrap {
  @apply 
  overflow-x-auto
  pb-24
}
  
.course_features__table {
  @apply flex flex-nowrap gap-6 lg:grid lg:grid-cols-3
}

.course__column {
  @apply flex-shrink-0 min-w-0 w-[300px] lg:w-fit;
}
  
.course__column__inner {
  @apply bg-white border border-[#DDDDDD] shadow-md flex flex-col h-full;
}

.course__head {
  @apply h-[170px] md:h-[200px] flex items-center justify-center flex-col px-4 text-center border-b border-[#DDDDDD];
}

.course__table__wrap::-webkit-scrollbar {
  height: 8px; /* スクロールバーの高さ */
}
.course__table__wrap::-webkit-scrollbar-thumb {
	background: #cecece; /* ツマミの色 */
	border-radius: 4px; /* ツマミ両端の丸み */
}
.course__table__wrap::-webkit-scrollbar-track {
	background: #fbfbfb; /* トラックの色 */
	border-radius: 4px; /* トラック両端の丸み */
}

.course__comment {
    @apply text-center
    mb-2
}
  
.course__head h3 {
  @apply text-[17px] md:text-xl font-bold mb-2;
}

.course__description {
  @apply text-sm md:text-base p-3 md:p-6 flex-grow;
}

.dual__course .badge {
}

.course__copy {
  @apply bg-[#FFF8D0] text-center p-3 mb-4 font-bold;
}

.more__buttton {
  @apply px-6 pb-3 md:pb-6;
}

/* Buttons */
.navy__button {
  @apply flex items-center justify-center w-full py-3 px-4 text-white font-bold rounded-md transition duration-300 relative;
}

.course1.navy__button {
  @apply bg-[#4d82e6] hover:opacity-80 rounded-full;
}

.course2.navy__button {
  @apply bg-[#f2795b] hover:opacity-80 rounded-full;
}

.course3.navy__button {
  @apply bg-[#f46aae] hover:opacity-80 rounded-full;
}

.simple__arrow {
  @apply ml-2 w-2 h-2 border-t-2 border-r-2 border-white transform rotate-45;
}

/* Voice Section */
.voice__column {
  @apply flex flex-col md:flex-row gap-6 items-center md:items-start border border-[#DDDDDD] rounded-lg p-6 shadow-sm;
}

.student__icon {
  @apply text-center w-24 flex-shrink-0;
}

.student__icon img {
  @apply mx-auto mb-2;
}

.voice_information {
  @apply flex-grow;
}

.voice__course {
  @apply flex flex-col md:flex-row gap-4 items-start md:items-center mb-4;
}

.voice__course .course {
  @apply bg-[#EEEEEE] px-3 py-1 rounded-full text-sm;
}

.course__name {
  @apply !text-white;

    & h3 {
      @apply leading-relaxed
    }
}
  

.voice__message {
  @apply relative bg-[#F8F8F8] p-6 rounded-lg;
}

.voice__message:before {
  @apply content-[''] absolute top-4 -left-3 w-0 h-0 border-solid border-8 border-transparent border-r-[#F8F8F8];
}

.voice_en {
  @apply text-[#999999] italic mb-2;
}


.plan__table {
	width: 100%;
	table-layout: fixed;

	th,
	td {
		border: 2px solid #fff;
		text-align: center;
		vertical-align: middle;
		font-weight: 500;
		padding: 1rem;

		th {
			width: 16%;
		}

		td {
			width: 28%;
		}
	}

	thead {
		img {
			width: auto;
			height: 50px;
		}
	}

	tbody {
	    @apply leading-loose;
		background: #f7f9fc;

		th {
		@apply text-lg;
			background: #eff1f6;
			text-align: center;
		}
		.course__name {
			color: #fff;
			line-height: 1.4;
			padding: 1.2em 0.5em;
		}
		.course__name.course3 {
			line-height: 1.2;
		}
		.badge {
			width: 80%;
			margin: 0 auto 8px auto;
		}
	}
	.dual__price {
		text-align: center;
		img {
			width: 90%;
		}
	}
	.price {
		font-family: "Roboto", sans-serif;
		  @apply md:text-4xl
	text-xl
	font-bold
	}
	.center__laeft {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: left;
		font-weight: 500;
	}
	strong {
		font-weight: 600;
	}
	.back__none {
		background: #fff;
	}
	.navy__button {
		width: 70%;
		padding: 0.6em;
		margin: auto;
	}
}

td:last-child, th:last-child {
  border-left: 2.5px solid #f46aae;
  border-right: 2.5px solid #f46aae;
}

/* セットプランの最後のセルに下ボーダー */
tr:last-child td:last-child, tr:last-child th:last-child {
  border-bottom: 2px solid red;
}

td.plan__type {
  @apply text-lg md:text-xl;
	padding: 0.5em;
}

@media (max-width: 767px) {
	.plan__table__wrap {
		flex-wrap: nowrap; /* 折り返しを防ぐ */
		overflow-x: auto; /* 横スクロール可能にする */
		scroll-snap-type: x mandatory; /* スナップスクロール（オプション） */
		-webkit-overflow-scrolling: touch; /* スクロールを滑らかにする */
		padding-bottom: 2em;
	}
	.plan__table__wrap::-webkit-scrollbar {
		height: 8px; /* スクロールバーの高さ */
	}
	.plan__table__wrap::-webkit-scrollbar-thumb {
		background: #cecece; /* ツマミの色 */
		border-radius: 4px; /* ツマミ両端の丸み */
	}
	.plan__table__wrap::-webkit-scrollbar-track {
		background: #fbfbfb; /* トラックの色 */
		border-radius: 4px; /* トラック両端の丸み */
	}
	.plan__table {
		width: 200%;

		th {
			width: 160px;
		}
		td {
			width: 240px;
		}
	}
}



.center__laeft {
  @apply text-left;
}

/* Responsive Horizontal Scroll */
.horizontal__scroll {
  @apply relative;
}

.slide__nav {
  @apply md:hidden absolute top-20 right-4 text-sm  px-3 py-1 rounded-full flex items-center text-[#4d82e6] font-medium;
}

/* Whats Frame Section Specific */
.whats__frame_1 {
  @apply mb-16;
}

.whats__frame2 {
}

.whats__frame__title {
  @apply text-xl md:text-2xl font-bold mb-6;
}

.whats__frame__description {
  @apply mb-6;
}

.about__frame_face_24type .title__belt {
  @apply mb-16;
  background: linear-gradient(135deg, #fce7c8 10%, #f4b7b8 90%);
}

/* Back None for Table */
.back__none {
  @apply border-none bg-white;
}

/* Media Queries for Special Cases */
@media (max-width: 768px) {
  .course_features__table {
    @apply px-4;
  }
  
  .plan__table th, .plan__table td {
    @apply p-2 text-sm;
  }
  
  .slide__nav {
    @apply flex;
  }
}
}
