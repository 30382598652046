.drawer-overlay {
    @apply z-[10];
}

.drawer-nav {
    @apply z-[11]
    pt-4;
}

.drawer-hamburger {
    padding: 15px 0.75rem 30px;
    @media (min-width: 765px) {
        padding: 18px 0.75rem 30px;
    }
}

.drawer-hamburger svg {
    @apply h-6;
}

.drawer-open .drawer-hamburger {
    @apply !right-1
    z-[12];
}

.drawer--right.drawer-open .drawer-nav,
.drawer--right .drawer-hamburger,
.drawer--right.drawer-open .drawer-navbar .drawer-hamburger {
    right: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}
