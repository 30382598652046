.post_container {
    & li {
        @apply border-b
            border-gray;
    }

    & .post_list {
        @apply py-5
        md:flex
        items-start
        justify-start;
        & .date {
            @apply text-primary
            text-sm
            font-semibold
            md:mr-6
            md:mb-0
            mb-4;
        }
        & .title {
            @apply text-sm;
        }
    }
}
