#pills-tab{
    & .nav-link{
        @apply border-gold
        border
        border-solid
        text-white
        bg-gold
        font-semibold
        text-center
        lg:text-2xl
        text-xl
        py-4;

        /* &.active{
            @apply bg-gold
            text-white

        } */
    }
}