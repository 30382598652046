.course_kiyaku {
    @apply overflow-y-scroll
    md:h-44
    h-32
    p-4
    border
    border-[#707070]
    mt-8;
    & .inner {
        & .ta-c {
            @apply text-center
            font-semibold;
        }

        & p {
            @apply mb-4
            text-sm;
        }
    }
}

