.scrolldown_animation {
    display: inline-block;
    position: relative;
    bottom: 0;
    z-index: 2;
    padding-bottom: 40px;
    overflow: hidden;
}
.scrolldown_animation::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 1px;
    height: 100px;
    background: #000;
}

.scrolldown_animation::after {
    animation: sdl 2s cubic-bezier(1, 0, 0, 1) infinite;
}
@keyframes sdl {
    0% {
        transform: scale(1, 0);
        transform-origin: 0 0;
    }
    30% {
        transform: scale(1, 1);
        transform-origin: 0 0;
    }
    70% {
        transform: scale(1, 1);
        transform-origin: 0 100%;
    }
    100% {
        transform: scale(1, 0);
        transform-origin: 0 100%;
    }
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
    @apply bg-primary;
}
