@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css";

button.primary {
  @apply relative
    border
    border-gold
    bg-gold
    text-white
    hover:text-gold
    hover:bg-white
    rounded-full
    tracking-widest
    transition-colors;
  &.cta {
    @apply bg-ctaPink
        border-ctaPink
        text-white
        hover:text-ctaPink
        hover:bg-white
        rounded-none;
    & a {
      @apply lg:px-12
            px-6;
    }
  }
  &:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f138";
    @apply absolute
        right-4
        top-1/2
        -translate-y-1/2;
  }

  & a {
    @apply py-5
        lg:px-20
        px-12
        block
        w-full;
  }
}

button.cta {
  @apply text-white
    font-semibold
    tracking-wide
    md:text-sm
    text-xs
    transition-colors;
  & a {
    @apply py-5
        px-20
        inline-block;
  }
  &.float a {
    @apply py-2
        px-0
        md:px-4
        inline-block;
  }
  &.vertical a {
    writing-mode: vertical-rl;
    @apply xl:px-3
        xl:py-4
        lg:px-4
        py-3
        px-2
        block;
  }
  &.blue {
    @apply bg-ctaBlue
        border
        border-ctaBlue
        hover:bg-white
        hover:text-ctaBlue;
  }
  &.green {
    @apply bg-ctaGreen
        border
        border-ctaGreen
        hover:bg-white
        hover:text-ctaGreen;
  }
  &.pink {
    @apply bg-ctaPink
        border
        border-ctaPink
        hover:bg-white
        hover:text-ctaPink;
  }

  &.gold {
    @apply bg-gold
        border
        border-gold
        hover:bg-white
        hover:text-gold;
  }
}

button.navigation {
  @apply relative
    border
    border-primary
    bg-primary
    text-white
    hover:text-primary
    hover:bg-white
    rounded-full
    tracking-widest
    font-semibold
    transition-colors
    after:transition-colors;
  & a {
    @apply py-5
        px-20
        inline-block;
  }
}
