@keyframes tooltipShow {
    from {
        opacity: 0;
        transform: translateY("5px");
    }

    to {
        opacity: 1;
        transform: translateY("0px");
    }
}

.hover_content {
    @apply hidden;
    &.active {
        @apply block;
        animation: tooltipShow 0.6s linear 0s;
    }
}
