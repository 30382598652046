@font-face {
    font-family: "north";
    src: url("images/font_NorthAvellion.ttf");
}

@font-face {
    font-family: "rank";
    src: url("images/font_RankingscapsRegular.woff");
}

@import url("https://fonts.googleapis.com/css?family=Lato:400,700,900|Lora:400,400i,700,700i");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Allura&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Allison&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Waterfall&display=swap');

.heading_primary {
    @apply flex
    flex-col-reverse;
    & p {
        @apply font-north
        xl:text-[140px]
        md:text-9xl
        text-6xl
        text-gold
        leading-none
        -rotate-[4deg]
        mt-8
        lg:mt-0;
        &.smaller{
            @apply text-5xl 
            md:text-6xl 
            lg:text-[80px]
        }
    }
    & h2 {
        @apply xl:text-3xl
        md:text-2xl
        text-xl
        mt-4
        font-normal
        leading-[1.7];
    }
}

.heading_secondary {
    @apply flex
    md:flex-row-reverse 
    flex-col-reverse
    md:items-end
    md:justify-end
    border-b
    border-gray
    pb-4;
    & span {
        @apply text-2xl
        leading-none
        font-semibold;
    }
    & h3 {
        @apply text-sm
        mt-2
        md:mt-0
        md:ml-4;
    }
}

.markers {
    display: inline;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
}

.markers.markers-yellow {
    background: linear-gradient(transparent 70%, #ffff7c 70%);
    padding: 0 2px;
}

.markers.markers-pink {
    background: linear-gradient(transparent 50%, rgba(249,196,222,0.4) 50%);
    padding: 0 2px;
}

.dot_text{
    @apply flex
    items-start
    justify-start
    before:content-["・"]
    before:text-primary
    before:text-[1.7rem]
    before:leading-none;
}
