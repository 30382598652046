.story_content,
.graduate_content {
    & a {
        @apply text-darkGold;
    }
}

.pagerArea a {
    @apply text-[#1f1f26];
}

.backBtn {
    @apply w-[165px];
}

#contentsArea {
    @apply text-[15px]
    leading-7
    mt-3
    lg:mt-6;
}

.topicpath__area {
    @apply !container
    max-w-none
    lg:mt-24
    mt-14;
}

.addPage .topicpath__area{
    @apply mt-0
}

.topicpaths {
    @apply w-full;
}

.story_title {
    @apply text-lg
    font-bold;
    &:before {
        @apply text-2xl;
    }
}

.storyOneTtl {
    @apply !text-[15px]
    !font-bold;
}

.storyCap {
    & p {
        @apply mb-1;
    }
    & p,
    & .profName {
        @apply !text-sm;
    }
}

#column.index #articles li {
    @apply w-full
    md:w-[257px]
    lg:w-[298px];
}

.columnArticle {
    & .columnTit {
        @apply leading-9;
    }

    & .excerpt {
        @apply text-[15px];
    }
}

.nextBtn a,
.prevBtn a,
.backBtn a {
    @apply h-[45px];
}

.interviewTtl {
    @apply lg:text-2xl
    text-lg
    font-bold
    pb-1;
}

#graduate.index #articles li .columnTit {
    @apply font-bold;
}

strong {
    @apply !font-bold;
}

.topicTit {
    @apply lg:!text-[22px]
    text-lg
    lg:leading-10
    leading-8;
}

.extract {
    @apply text-sm
    leading-6;
}

#navArea li {
    @apply lg:text-sm
    text-[15px]
    leading-[27px];
}

#navArea li a {
    @apply !text-darkGold;
}

.archiveTit {
    @apply text-[15px]
    lg:leading-[27px]
    leading-[22px]
    font-bold;
}

.excerpt {
    @apply text-[15px];
}

.formTbl {
    @apply text-[15px];
}

.necessary {
    @apply !h-[17px];
}

.w_100 {
    @apply max-w-none;
}

.leadTit_h3 {
    @apply leading-10;
}

.section p {
    @apply leading-7
    text-[15px];
}

.ttl4 {
    @apply text-lg
    leading-8;
}

.ttl5 {
    @apply font-bold;
}

.ttl6 {
    @apply font-bold;
}

.courseName {
    @apply font-bold
    lg:leading-10
    leading-5;
}

.schedule p {
    @apply text-[15px];
}

.courcePriceTbl h4 {
    @apply text-[15px]
    leading-7
    font-bold
    py-1;
}

.courcePriceTbl th,
.courcePriceTbl td {
    @apply text-[15px];
}

.curriculumTtl {
    @apply !text-lg
    lg:!text-2xl
    lg:leading-10
    leading-5;
}

.lead_text {
    @apply text-base
    
    lg:!text-lg
    !leading-8;
}

.cource p {
    @apply text-[15px]
    leading-7;
}

.title3 {
    @apply lg:text-2xl
    text-lg
    leading-10
    font-bold;
}

.read_text_inner p {
    @apply lg:text-xl
    text-base
    lg:leading-9;
}

.tokuchoTtl {
    @apply font-bold;
}

.txtA {
    @apply leading-10;
}

.question_title {
    @apply text-2xl
    font-bold;
}

.make_why_block p,
.make_why_block li {
    @apply text-[15px]
    leading-7;
}

.tokuchoTtl2 {
    @apply font-bold;
}

.page_title2 {
    @apply text-2xl
    leading-10
    font-bold;
    & span {
        @apply text-lg;
    }
}

.example__column {
    & h4 {
        @apply font-bold
        text-base;
    }
}
